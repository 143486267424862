.custom_input {
  width: 100%;
  margin: 0.687rem 0;
}

.custom_input_ele {
  background-color: rgb(0, 0, 0, 0.4);
  border-radius: 6px;
  height: 51px;
  border: none;
  padding-left: 1.5rem;
  font-size: 1.125rem;
  letter-spacing: 1.08px;
  line-height: 2.375rem;
  color: var(--white);
  width: 100%;
  max-width: 379px;
}

.custom_error {
  border: 2px solid var(--red);
}

.custom_input_ele input,
.custom_input_ele input::placeholder {
  background: rgb(0, 0, 0, 0.4);
  color: var(--white);
}
.custom_input_ele::placeholder {
  color: var(--white);
  opacity: 0.6;
}

.error_message {
  width: 100%;
  max-width: 379px;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: var(--red);
  margin: 0;
}

