.form_container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  background-size: cover;
  background-position: center;
}

.form_inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 50%;
  min-height: 100vh;
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 379px;
}

.form_title {
  font-size: 1.875rem;
  line-height: 2.313rem;
  font-family: "montserrat", sans-serif;
  font-weight: normal;
  margin-bottom: 2.19rem;
  margin-top: 0;
}

.form_title img {
  width: 24px;
  height: 24px;
  margin-right: 0.875rem;
}
.form_input_content {
  display: flex;
  flex-direction: column;
}

.form_content {
  background: url("../../img/background_flower/background\ flower@2x.png")
    no-repeat padding-box;
  background-size: cover;
  background-position: 20% center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: justify;
  flex: 50%;
  height: 100vh;
}

.form_content img {
  width: 40%;
  height: auto;
}

.form_content p {
  font-size: 1.125rem;
  letter-spacing: 1.08px;
  line-height: 1.5rem;
  margin-top: 5.5rem;
  width: 420px;
}

@media screen and (max-width: 62rem) {
  .form_container {
    background: url("../../img/background_flower/background\ flower@2x.png");
    background-size: cover;
    background-position: center center;
    flex-direction: column-reverse;
    justify-content: center;
    height: fit-content;
  }
  .form_content {
    background: none;
    flex: 30%;
    justify-content: flex-end;
    margin-bottom: 3rem;
    margin-top:2rem;
  }
  .form_content p {
    display: none;
  }
  .form_inputs {
    width: 100vw;
    justify-content: flex-start;
  }
  .login .form_inputs form {
    margin-top: 80px;
  }
}

@media screen and (max-width: 48rem) {
  .form_container {
    background: var(--white);
  }
  .form {
    max-width: 341px;
  }
  .form_content {
    flex: 10%;
    justify-content: flex-end;
    margin-bottom: 3rem;
  }

  .form_title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  .login .form_inputs form {
    margin-top: 40px;
  }
}

@media screen and (max-width: 24rem) {
  .form {
    max-width: 250px;
  }

  .form_content {
    margin-top: 1rem;
  }
}
