.home_artist_section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home_artist_section_main {
  text-align: center;
  margin-top: 3.75rem;
  width: 35%;
}
.home_artist_title {
  font-size: 2.5rem;
  line-height: 2.375rem;
  letter-spacing: 2.4px;
  margin-bottom: 2rem;
  font-weight: 300;
  font-family: "montserrat", sans-serif;
}

.home_artist_content {
  font-family: "roboto", sans-serif;
  font-size: 1rem;
  line-height: 2rem;
  letter-spacing: 0.96px;
  font-weight: 400;
}

.home_artist_more {
  margin-bottom: 5.75rem;
  display: flex;
  justify-content: space-evenly;
}

.home_artist_see_more {
  display: none;
}

@media screen and (max-width:62rem) {
  .home_artist_section_main {
    width: 50%;
  }
  .home_artist_more {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 48rem) {
  .home_artist_section{
    margin-bottom: 0;
  }
  
  .home_artist_section_main {
    width: 90%;
  }
  .home_artist_title {
    font-size: 2rem;
    line-height: 2rem;
    margin-bottom: 1.5rem;
  }
  .home_artist_see_more {
    display:contents;
    text-transform: uppercase;
    font-size: 1rem;
    font-family: "montserrat", sans-serif;
    font-weight: 600;
    color: var(--green);
  }
  .home_artist_more {
    display: none;
  }
}
