.artist_content_container {
  padding: 37px 0 79px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 50px 0 50px;
}

.artist_content_info {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  min-height: 30vh;
}

.page_numbers {
  margin-top: 40px;
  align-self: flex-end;
}

.page_numbers ul {
  display: flex;
  align-items: center;
}

.page_numbers ul li {
  line-height: 1.5;
  font-weight: 600;
  font-family: "montserrat", sans-serif;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px 0 8px;
  position: static;
}

.page_numbers ul li img {
  vertical-align: middle;
}
.ant-pagination-item a {
  color: var(--black);
}

.ant-pagination-item-active a {
  background: var(--green);
  border-radius: 6px;
  width: 40px;
  height: 40px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-pagination-item {
  background: var(--white);
  border: none;
}

.individual_artist_container {
  width: 90%;
  margin-left: 150px;
}

.individual_artist_info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1269px;
}

.individual_artist_contact {
  display: flex;
  align-items: center;
  font-size: 1.31rem;
  line-height: 1.5;
  font-weight: 300;
  max-width: 1350px;
  flex-wrap: wrap;
}

.individual_artist_tags {
  align-self: flex-end;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-wrap: wrap;
  max-width: 28vw;
  gap: 10px;
}

.individual_artist_tags .tag_wrapper {
  margin-top: 0;
}
.individual_artist_links,
.individual_artist_links a {
  color: var(--green);
  text-decoration: none;
}

.individual_artist_portfolio {
  display: flex;
  margin-top: 51px;
  width: 100%;
}
.individual_artist_description {
  display: flex;
  flex-direction: column;
  width: 267px;
}
.individual_artist_description p {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 300;
  margin-top: 15px;
}
.individual_artist_secondary_title {
  font-size: 1rem;
  line-height: 1.5;
  font-family: "montserrat", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}

.individual_artist_portfolio_image_container {
  display: flex;
  flex-direction: column;
  margin-left: 42px;
  width: 100%;
}

.individual_artist_portfolio_image_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1020px;
}

.individual_artist_portfolio_image {
  width: 495.99px;
  height: 266.92px;
  border-radius: 10px;
  margin-top: 20px;
  border: 1px solid #d6d6d6;
}

.individual_artist_contact .dot {
  margin-top: 10px;
  margin-left: 6px;
  margin-right: 6px;
}

@media screen and (max-width: 66rem) {
  .artist_content_container {
    margin: 0;
  }

  .artist_content_info {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }
  .page_numbers {
    margin-top: 40px;
    align-self: center;
    display: flex;
    justify-content: center;
  }
  .individual_artist_container {
    margin-left: 0;
  }
  .individual_artist_contact {
    flex-direction: column;
    align-items: flex-start;
  }
  .individual_artist_contact .dot {
    display: none;
  }
  .individual_artist_tags {
    max-width: 100%;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 48rem) {
  .artist_content_container {
    display: block;
    clear: both;
  }
  .individual_artist_container {
    margin-top: 50px;
  }
  .individual_artist_info {
    flex-direction: column;
  }
  .individual_artist_tags {
    margin-top: 20px;
  }
  .individual_artist_portfolio {
    flex-direction: column;
    align-items: center;
  }
  .individual_artist_description {
    width: 90vw;
  }
  .individual_artist_portfolio_image_container {
    margin-left: 0;
    margin-top: 20px;
  }
  .individual_artist_portfolio_image_wrapper {
    width: 90vw;
  }
  .individual_artist_portfolio_image {
    width: 100%;
    height: auto;
  }
  .individual_artist_contact {
    width: 100%;
    word-wrap: break-word;
  }
  .individual_artist_contact p {
    font-size: 1rem;
    width: 100%;
    word-wrap: break-word;
  }
}
