.modal_background_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--modal_background);
  z-index: 90;
}

.modal_container {
  position: fixed;
  background: var(--white);
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  box-shadow: 0px 0px 16px #00000029;
  z-index: 90;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
}

.modal_title {
  background: var(--black);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.125rem 1.875rem;
  border-radius: 10px 10px 0 0;
}

.modal_title h3 {
  font-size: 1.125rem;
  text-transform: uppercase;
  font-family: "montserrat", sans-serif;
  line-height: 1.375rem;
  font-weight: 300;
  margin: 0;
}

.modal_title img {
  color: var(--white);
}

.modal_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.875rem;
}

@media screen and (max-width: 62rem) {
  .modal_container {
    max-height: calc(100vh - 90px);
    overflow-y: auto;
    width: 95vw;
  }
}

@media screen and (max-width: 48rem) {
  .modal_container {
    max-height: calc(100vh - 90px);
    overflow-y: auto;
  }
}



