.loan_process_container {
  display: flex;
  width: 80vw;
  height: 22rem;
  border: 1px solid var(--black);
  border-radius: 10px;
  padding: 50px 40px;
  margin: 20px 0 20px 0;
  background-color: var(--black);
  color: var(--white);
  background-image: url("../../../img/background_tattoo/background_tattoo.svg");
  background-repeat: no-repeat;
  background-position: right;
}

.loan_denied_container {
  width: 300px;
  display: flex;
  align-items: center;
  padding-left: 30px;
}

.loan_approval_steps_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 350px;
}

.loan_agreement_steps {
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.5;
  text-transform: uppercase;
  font-family: "montserrat", sans-serif;
  font-weight: 600;
}
.loan_agreement_not_completed_steps {
  color: #909090;
}
.loan_agreement_active_steps {
  color: var(--white);
}
.loan_agreement_completed_steps {
  color: var(--green);
}
.loan_agreement_steps p {
  margin-left: 10px;
}

.loan_card_title {
  font-size: 2rem;
  line-height: 1.5;
  font-weight: bold;
  font-family: "roboto", sans-serif;
  margin-bottom: 19px;
}

.loan_details_container {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
}

.loan_details_text_container,
.loan_details_inner_text_container {
  display: flex;
  justify-content: space-between;
}
.loan_details {
  margin-right: 60px;
}
.loan_details_title {
  font-size: 0.875rem;
  line-height: 1.5;
  color: rgb(255, 255, 255, 0.75);
}

.loan_details_approved_text_container {
  display: flex;
}

.loan_details_values {
  font-size: 1.312rem;
  line-height: 1.5;
  font-weight: bold;
}
.loan_detail_buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
}

.sm_decline {
  background: url("../../../img/buttons/btn_decline.svg");
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  width: 179px;
  height: 51px;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--white);
  margin-left: 20px;
}
.denied {
  color: var(--red);
}
.sm_approve {
  background: url("../../../img/buttons/btn_approve.svg");
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  width: 179px;
  height: 51px;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--white);
}

.sm_approve:hover,
.sm_decline:hover {
  filter: brightness(0.85);
}
@media screen and (max-width: 82.5rem) {
  .loan_details {
    margin-right: 40px;
  }
}
@media screen and (max-width: 62rem) {
  .loan_process_container {
    height: auto;
    width: 95vw;
    padding: 40px 30px;
  }
  .loan_details_container {
    flex-grow: 1;
    margin-left: 20px;
    height: auto;
    width: 100%;
  }
  .loan_details_container button {
    margin-top: 20px;
  }
  .loan_denied_container {
    width: 50%;
  }
  .loan_details_text_container {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 48rem) {
  .loan_process_container {
    width: 95vw;
    padding: 20px 10px;
  }
  .loan_process_container {
    flex-direction: column;
  }
  .loan_agreement_steps {
    margin: 10px 0 10px 0;
  }
  .loan_approval_steps_container {
    width: 100%;
    align-items: center;
  }
  .loan_details_container {
    margin-top: 20px;
    margin-left: 0;
    align-items: center;
    width: 100%;
  }
  .loan_details_container button {
    margin-left: 0;
  }
  .loan_details_text_container {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .loan_details_inner_text_container {
    display: flex;
    flex-direction: column;
  }
  .loan_details {
    margin: 0;
    padding: 0;
    margin-top: 20px;
  }
  .loan_detail_buttons {
    flex-direction: column;
    margin-top: 20px;
  }
  .loan_denied_container {
    width: 100%;
    justify-content: center;
    padding: 0;
  }
  .denied {
    text-align: justify;
    padding: 10px;
  }
  .loan_approved_text {
    width: 100%;
    padding: 0 20px;
  }
  .loan_details_approved_text_container {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .loan_details_approved_text_container .loan_details {
    margin-right: 0;
  }
  .loan_approve_message {
    text-align: justify;
    width: 100%;
  }
}
