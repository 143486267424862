.id_verification_frame {
  height: 70vh;
  width: 50vw;
}
.contract_container{
  width: 75vw;;
}
@media screen and (max-width: 62rem) {
  .id_verification_frame {
    height: 90vh;
    width: 90vw;
  }
  .contract_container{
    width: 100%;
  }
}


@media screen and (max-height: 44rem) {
  .contract_container{
    width: 100%;
  }
}
