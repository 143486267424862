.profile_province_select {
  margin: 5px 0;
  background-color: #ededed;
  border-radius: 6px;
  height: 51px;
  border: none;
  padding-left: 1.5rem;
  color: var(--black);
  width: 100%;
  max-width: 379px;
  font-size: 1rem;
  letter-spacing: 0.96px;
  line-height: 2.375rem;
}

.profile_province_select:focus {
  color: var(--black);
  font-size: 1rem;
  letter-spacing: 0.96px;
}

.profile_province_select option {
  font-family: "roboto", sans-serif;
  font-size: 1rem;
  letter-spacing: 0.96px;
  line-height: 2.375rem;
}

.edit_tag_input {
  width: 379px;
  height: 120px;
  border-radius: 6px;
  background-color: #ededed;
  position: relative;
  margin-bottom: 5px;
}

.edit_tag_input input {
  width: 379px;
  height: 51px;
  border-radius: 6px;
  background-color: #ededed;
  border: none;
  text-align: end;
  padding-right: 5px;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 1rem;
  letter-spacing: 0.96px;
  line-height: 2.375rem;
}

.edit_tag_input input:focus {
  outline: none;
  text-align: end;
}
.edit_tag_list {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  list-style: none;
}
.edit_tag_container {
  display: flex;
  background: var(--green);
  border-radius: 4px;
  margin-left: 5px;
  margin-top: 5px;
  padding: 3px 6px;
}
.edit_tag_container div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 7px;
}
.edit_tag {
  color: var(--white);
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  text-transform: capitalize;
}

.edit_close_icon {
  width: 11px;
  height: 11px;
  background: transparent;
}
@media screen and (max-width: 64rem) {
  .save_changes_button {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 48rem) {
  .edit_tag_input {
    width: 100%;
    height: 150px;
  }
  .edit_tag_input input {
    width: 100%;
  }
}
