.about_us_detail {
  width:40vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0.625rem 1.468rem;
}

.about_us_detail h3 {
  font-size: 1.875rem;
  font-family: "montserrat", sans-serif;
  letter-spacing: 1.8px;
  line-height: 2.375rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-weight: 400;
}

.about_us_detail p {
  font-size: 0.875rem;
  letter-spacing: 0.84px;
  line-height: 1.5rem;
  font-family: "roboto", sans-serif;
  margin-bottom: 1rem;
  font-weight: 400;
}

.see_more {
  text-transform: uppercase;
  font-size: 1rem;
  font-family: "montserrat", sans-serif;
  font-weight: 600;
  color: var(--green);
}

@media screen and (max-width: 62rem) {
  .about_us_detail {
    width: 90vw;
  }
}

@media screen and (max-width: 48rem) {
  .about_us_detail {
    width: 90vw;
  }
}
