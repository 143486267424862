.artist_dashboard_searchbar_container {
  position: absolute;
  top: 28vh;
  right: 12vw;
  z-index: 10;
}

.artist_dashboard_searchbar_container input {
  font-size: 1.25rem;
  line-height: 1.9;
  letter-spacing: 1.08px;
  padding-left: 50px;
  height: 51px;
  width: 35vw;
  border-radius: 6px;
  background: rgb(0, 0, 0, 0.6);
  color: var(--white);
}

.artist_dashboard_searchbar_container input::placeholder {
  font-size: 1.25rem;
  line-height: 1.9;
  letter-spacing: 1.08px;
  color: rgb(255, 255, 255, 0.43);
}

.artist_dashboard_search_icon {
  position: absolute;
  top: 25%;
  left: 2%;
}
.current_clients_main_container {
  margin: 32px 0 78px 0;
  display: flex;
  justify-content: center;
}
.current_clients_main_details_container {
  margin: 32px 0 78px 0;
  display: flex;
  margin-left: 158px;
}

.pending_payout_container {
  margin-right: 39px;
  background-color: var(--bg_grey);
  padding: 16px 24px 18px 24px;
  border-radius: 6px;
  height: fit-content;
}

.pending_payout {
  font-size: 2.25rem;
  line-height: 1.5;
  color: var(--green);
  font-weight: 600;
  font-family: "montserrat", sans-serif;
}
.pending_payout p {
  font-size: 1rem;
  line-height: 1.5;
}
.pending_payout_message {
  color: rgb(38, 38, 38, 0.6);
  margin: 12px 0 36px 0;
}

.current_client_container {
  display: flex;
  flex-direction: column;
}
.secondary_screen_client_title_container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.individual_client_details {
  font-size: 1.31rem;
  line-height: 1.5;
  color: rgb(255, 255, 255, 0.5);
  text-transform: none;
  display: flex;
  overflow: auto;
}

.grey_dot {
  margin: 0 4px 4px 4px;
  width: 3px;
  height: 3px;
  background: rgb(255, 255, 255, 0.5);
  display: inline-block;
  border-radius: 50%;
}
.individual_client_title_details {
  width: 100%;
  overflow: auto;
}

.secondary_screen_client_title_container .lg_green_button {
  margin-right: 95px;
}

.no_current_clients {
  width: 57vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 95rem) {
  .artist_dashboard_searchbar_container {
    right: 5vw;
    z-index: 10;
  }
  .individual_client_details {
    flex-direction: column;
  }
  .grey_dot {
    display: none;
  }
}
@media screen and (max-width: 82.5rem) {
  .artist_dashboard_searchbar_container {
    position: relative;
    top: auto;
    right: auto;
    display: flex;
    justify-content: center;
    margin-top: 37px;
  }
  .artist_dashboard_searchbar_container input {
    width: 50vw;
    background: rgb(0, 0, 0);
  }
  .artist_dashboard_searchbar_container input::placeholder {
    color: rgb(255, 255, 255, 0.9);
  }
  .artist_dashboard_search_icon {
    position: absolute;
    top: 25%;
    left: 26%;
  }
  .current_clients_main_details_container {
    margin-left: 0;
    justify-content: center;
  }

  .current_clients_main_container {
    flex-direction: column;
    align-items: center;
  }
  .pending_payout_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0;
    background-color: var(--bg_grey);
    padding: 16px 24px 18px 24px;
    border-radius: 6px;
    height: fit-content;
  }
  .secondary_screen_client_title_container {
    flex-direction: column;
    overflow: auto;
    overflow-wrap: break-word;
    width: 90vw;
  }
  .secondary_screen_client_title_container button {
    position: relative;
    top: auto;
    right: auto;
    display: flex;
    justify-content: center;
    margin-top: 37px;
  }
  .secondary_screen_client_title_container .lg_green_button {
    margin-right: 0;
  }
  .no_current_clients {
    width: 90vw;
  }
  .client_details_header_details .complete_tattoo_button {
    margin-right: 0;
  }
}
@media screen and (max-width: 48rem) {
  .artist_dashboard_searchbar_container input {
    width: 90vw;
    background: rgb(0, 0, 0);
  }
  .artist_dashboard_search_icon {
    position: absolute;
    top: 25%;
    left: 8%;
  }
  .individual_client_details {
    width: 100%;
    overflow: auto;
    overflow-wrap: break-word;
  }
  .individual_client_details p {
    width: 100%;
    font-size: 1rem;
    overflow-wrap: break-word;
    line-height: 1.5;
  }

  .no_current_clients img {
    max-width: 100%;
  }
}
