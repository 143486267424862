.feedback_container {
  width: 686px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feedback_subject_select {
  margin: 5px 0;
  background-color: #ededed;
  border-radius: 6px;
  height: 51px;
  border: none;
  padding-left: 1.5rem;
  color: var(--black);
  width: 100%;
  font-size: 1rem;
  letter-spacing: 0.96px;
  line-height: 2.375rem;
}

.feedback_subject_select:focus {
  color: var(--black);
  font-size: 1rem;
  letter-spacing: 0.96px;
}

.feedback_subject_select option {
  font-family: "roboto", sans-serif;
  font-size: 1rem;
  letter-spacing: 0.96px;
  line-height: 2.375rem;
}

.feedback_message {
  background-color: #ededed;
  border-radius: 6px;
  height: 150px;
  border: none;
  padding-left: 1.5rem;
  padding-top: 14px;
  font-size: 1rem;
  letter-spacing: 0.96px;
  line-height: 1.5rem;
  color: var(--black);
  width: 100%;
  font-family: "roboto", sans-serif;
  margin: 24px 0 24px 0;
}

.feedback_message::placeholder {
  color: var(--black);
  opacity: 0.28;
}

@media screen and (max-width: 62rem) {
  .feedback_container {
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
