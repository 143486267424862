.login_button {
  margin-top: 3.62rem;
}
.login_secondary_buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1.48rem;
}
.login_secondary_buttons button {
  background: transparent;
  border: none;
  outline: none;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.19rem;
  font-family: "montserrat", sans-serif;
  font-weight: 600;
  padding: 0;
}

.login_secondary_buttons button:hover,
.login_secondary_buttons button:focus {
  opacity: 0.6;
  text-decoration: underline;
  cursor: pointer;
}

.hidden {
  display: none;
}

.login input {
  width: 100%;
}

.general_error {
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: var(--red);
  margin: 0;
}
@media screen and (max-width: 48rem) {
  .login_secondary_buttons {
    width: 100%;
  }
}
@media screen and (max-width: 24rem) {
  .login_secondary_buttons button {
    font-size: 0.8rem;
    line-height: 1.19rem;
  }
  .login_secondary_buttons {
    margin-bottom: 1.48rem;
  }
}
