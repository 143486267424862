.customer_artist_container {
  position: relative;
  z-index: 10;
}

.searchbar_container {
  position: absolute;
  top: -100px;
  right: 12%;
  z-index: 10;
}

.searchbar_container input {
  font-size: 1.25rem;
  line-height: 1.9;
  letter-spacing: 1.08px;
  padding-left: 50px;
  height: 51px;
  width: 35vw;
  border-radius: 6px;
  background: rgb(0, 0, 0, 0.6);
  color: var(--white);
}

.searchbar_container input::placeholder {
  font-size: 1.25rem;
  line-height: 1.9;
  letter-spacing: 1.08px;
  color: rgb(255, 255, 255, 0.43);
}

.search_icon {
  position: absolute;
  top: 25%;
  left: 2%;
}

.individual_artist_title_content {
  display: flex;
  align-items: center;
  overflow: auto;
  overflow-wrap: break-word;
}

.individual_artist_title {
  font-size: 2.625rem;
  line-height: 1.5;
  font-weight: bold;
  font-family: "roboto", sans-serif;
  text-transform: capitalize;
}

.individual_studio_title {
  font-size: 2rem;
}

.individual_address {
  font-size: 1.31rem;
  line-height: 1.5;
  color: rgb(255, 255, 255, 0.5);
  text-transform: capitalize;
}

.secondary_screen_artist_title_container {
  display: flex;
  align-items: center;
  width: 80vw;
  justify-content: space-between;
}
.secondary_screen_title_new_loans_blocked {
  display: flex;
  align-items: flex-start;
  width: 80vw;
  justify-content: space-between;
}

.lg_green_inactive {
  background: url("../../../img/buttons/btn_green_large_inactive.svg");
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  width: 379px;
  height: 51px;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--white);
}

.account_blocked {
  padding-top: 30px;
}

.account_blocked_message {
  background-color: var(--black);
  margin-top: 10px;
  font-size: 1rem;
  color: var(--red);
  line-height: 1.5;
  text-transform: none;
  font-weight: normal;
}

@media screen and (max-width: 66rem) {
  .searchbar_container {
    position: relative;
    top: auto;
    right: auto;
    display: flex;
    justify-content: center;
    margin-top: 37px;
  }
  .searchbar_container input {
    width: 50vw;
    background: rgb(0, 0, 0);
  }
  .searchbar_container input::placeholder {
    color: rgb(255, 255, 255, 0.9);
  }
  .search_icon {
    position: absolute;
    top: 25%;
    left: 26%;
  }
  .individual_studio_title {
    display: none;
  }
  .account_blocked {
    padding-top: 10px;
  }
}

@media screen and (max-width: 48rem) {
  .searchbar_container input {
    width: 90vw;
    background: rgb(0, 0, 0);
  }
  .search_icon {
    position: absolute;
    top: 25%;
    left: 8%;
  }
  .secondary_screen_artist_title_container {
    flex-direction: column;
    width: 90vw;
  }
  .secondary_screen_artist_title_container button {
    position: relative;
    top: auto;
    right: auto;
    display: flex;
    justify-content: center;
    margin-top: 37px;
    z-index: 2;
  }
  .secondary_screen_title_new_loans_blocked {
    flex-direction: column;
    align-items: center;
  }

  .lg_green_inactive {
    position: relative;
    top: auto;
    right: auto;
    display: flex;
    justify-content: center;
    margin-top: 37px;
    background-size: contain;
    background-repeat: no-repeat;
    width: 341px;
    height: 45.9px;
  }
  .account_blocked {
    padding: 0;
  }
  .account_blocked_message {
    background: var(--white);
  }
  .individual_artist_title {
    font-size: 2rem;
    line-height: 1.5;
    font-weight: bold;
    font-family: "roboto", sans-serif;
    text-transform: capitalize;
  }
  .individual_artist_title_content {
    width: 100%;
  }
}
