.contact_us {
  background: var(--black) url("../../../img/background_tattoo_large.svg")
    no-repeat border-box;
  background-position: center right;
  background-size: auto 100%;
}

.contact_us_content {
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact_us_title {
  color: var(--white);
  margin-top: 5.188rem;
  font-size: 2.5rem;
  letter-spacing: 2.4px;
  line-height: 2.375rem;
  font-weight: 300;
  font-family: "montserrat", sans-serif;
}

.contact_us_form {
  display: flex;
  flex-direction: column;
  width: 56.2%;
}

.contact_us_sender {
  display: flex;
  justify-content: space-between;
}

.contact_us_form_input {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 0.625rem;
  flex-grow: 1;
}

.contact_us_form label {
  font-size: 1rem;
  letter-spacing: 0.96px;
  line-height: 2.375rem;
  color: var(--grey_label);
  font-family: "montserrat", sans-serif;
  font-weight: 300;
}

.contact_us_sender input {
  height: 59px;
  border: 1px solid var(--white);
  border-radius: 4px;
  background-color: transparent;
  padding-left: 1.68rem;
}

.contact_us_form_text {
  display: flex;
  flex-direction: column;
  margin: 2.06rem 0 0 0.625rem;
}

.contact_us_form textarea {
  width: 100%;
  height: 147px;
  border: 1px solid var(--white);
  border-radius: 4px;
  background-color: transparent;
  padding-left: 1.68rem;
  padding-top: 1.06rem;
}

.contact_us_sender input,
.contact_us_sender input::placeholder {
  font-size: 1.31rem;
  letter-spacing: 1.26px;
  line-height: 2.375rem;
  font-family: "montserrat", sans-serif;
  font-weight: 300;
  color: var(--white);
}

.contact_us_form textarea,
.contact_us_form textarea::placeholder {
  font-size: 1.31rem;
  letter-spacing: 1.26px;
  line-height: 2.375rem;
  font-family: "montserrat", sans-serif;
  font-weight: 300;
  color:var(--white);
  background-color: transparent;
}

.contact_us_button {
  align-self: center;
  margin-top: 5.25rem;
  margin-bottom: 10.19rem;
}

@media screen and (max-width: 62rem) {
  .contact_us_sender {
    flex-direction: column;
  }
  .contact_us {
    background-size: auto 70%;
  }
  .contact_us_form_input {
    margin-top: 2.06rem;
  }
  .contact_us_form {
    width: 90%;
  }
}

@media screen and (max-width: 48rem) {
  .contact_us_sender input,
  input::placeholder {
    height: 40px;
    font-size: 1rem;
    line-height: 2rem;
  }
  .contact_us_form textarea,
  textarea::placeholder {
    font-size: 1rem;
    line-height: 2rem;
  }
  .contact_us_button {
    align-self: center;
    margin-top: 5rem;
    margin-bottom: 6rem;
  }
}
