.client_card_container {
  width: 57vw;
  display: flex;
  align-items: flex-start;
  height: 16rem;
  border: 1px solid var(--black);
  border-radius: 10px;
  padding: 23px 30px;
  margin-bottom: 20px;
  background-color: var(--black);
  color: var(--white);
  background-image: url("../../../img/background_tattoo/background_tattoo.svg");
  background-repeat: no-repeat;
  background-position: right;
}

.client_card_content_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.client_card_details_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.client_card_title {
  font-size: 2rem;
  line-height: 1.5;
  font-weight: bold;
}

.client_contact_details {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  line-height: 1.5;
  color: rgb(255, 255, 255, 0.5);
}

.client_contact_details .dot {
  background-color: rgb(255, 255, 255, 0.5);
}

.client_card_status {
  font-size: 1.125rem;
  line-height: 2.3;
  color: var(--green);
  font-family: "roboto", sans-serif;
  font-weight: bold;
  letter-spacing: 1.08px;
  margin-bottom: 16px;
}

.client_card_details_text_container {
  display: flex;
  width: 100%;
}

.client_card_details {
  margin-right: 76px;
}

.client_card_details_title {
  font-size: 0.875rem;
  line-height: 1.5;
  color: rgb(255, 255, 255, 0.75);
}

.client_card_details_values {
  font-size: 1.312rem;
  line-height: 1.5;
  font-weight: bold;
}

.client_card_buttons {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 379px;
}

.complete_tattoo_button {
  background: url("../../../img/buttons/btn_green_outline_large.svg");
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  width: 379px;
  height: 51px;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--green);
  margin-top: 16px;
}

.complete_tattoo_button:hover {
  filter: brightness(0.85);
}

.complete_tattoo_button:disabled {
  filter: brightness(0.85);
}

.client_card_buttons .button_view_more {
  margin-top: auto;
}

@media screen and (max-width: 98rem) {
  .client_card_details {
    margin-right: 40px;
  }
  .client_details_header_details button {
    margin-right: 0;
  }
}

@media screen and (max-width: 82.5rem) {
  .client_card_container {
    width: 90vw;
  }
  .client_card_buttons .lg_green_button,
  .client_card_buttons .lg_button_inactive {
    width: 296px;
    height: 45.9px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .complete_tattoo_button {
    margin-right: 0;
  }
  .client_card_details {
    margin-right: 20px;
  }
}
@media screen and (max-width: 62rem) {
  .client_card_buttons .lg_green_button,
  .complete_tattoo_button,
  .client_card_buttons .lg_button_inactive {
    width: 296px;
    height: 45.9px;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
@media screen and (max-width: 48rem) {
  .client_card_container {
    width: 90vw;
    height: auto;
  }
  .client_card_content_container {
    flex-direction: column;
    align-items: center;
  }
  .client_card_details_text_container {
    justify-content: space-between;
  }
  .client_card_buttons {
    padding-top: 16px;
  }
  .client_card_details {
    margin-right: 0;
  }
  .client_contact_details {
    flex-direction: column;
    align-items: flex-start;
  }
  .client_contact_details .dot {
    display: none;
  }
  .client_card_buttons .button_view_more {
    margin-top: 20px;
  }
  .client_card_status {
    margin-top: 15px;
    text-align: center;
  }
}

@media screen and (max-width: 24rem) {
  .complete_tattoo_button {
    max-width: 250px;
  }
}
