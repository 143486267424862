.nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: white;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 80;
}

.active {
  color: var(--green);
}

.navbar {
  height: 62px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.nav_collapse {
  display: none;
  background: var(--white);
}

.nav-link {
  margin-right: 1.25em;
  text-transform: uppercase;
  overflow: hidden;
}

.nav-icon-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--black);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
}

.nav-icon-link:hover,
.nav-icon-link:focus {
  color: var(--black);
  opacity: 0.6;
}

.active {
  color: var(--green);
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
}
.nav-link .active:hover {
  color: var(--green);
  opacity: 0.6;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 5px;
  background: var(--white);
}

.nav-button-link {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-decoration: none;
  min-height: 0;
}

.nav-button-link:hover,
.nav-button-link:focus {
  text-decoration-color: var(--white);
}

.nav_sign-up {
  background-image: url("../../../img/buttons/btn_black_outline_small.svg");
}

.nav_login {
  background-image: url("../../../img/buttons/btn_green_small.svg");
}

.nav_sign-up,
.nav_login {
  width: 111px;
  height: 37px;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav_sign-up:hover,
.nav_login:hover {
  filter: brightness(0.85);
}

.nav_login:hover {
  color: var(--white);
}

.text-black {
  color: var(--black);
}

.text-white {
  color: var(--white);
}

@media screen and (max-width: 62rem) {
  .nav_collapse {
    padding: 0.5em 1em;
    display: inline-block;
    font-size: 1.25rem;
    color: var(--black);
    cursor: pointer;
    outline: none;
    border: none;
  }
  .nav {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-end;
    z-index: 80;
    position:absolute;
  }
  .nav-link {
    margin: 0.625em;
  }

  .navbar {
    height: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 2;
    width: fit-content;
    background: var(--white);
  }
  .show-nav {
    height: auto;
    background: var(--white);
    position: fixed;
    top: 4.5%;
  }
  .nav_hidden {
    display: none;
  }

  .nav_collapse .icon {
    width: 2rem;
    height: 2rem;
  }
}
