.customer_header {
  background-position: right;
  background-repeat: no-repeat;
  height: 30vh;
  background-size: auto 100%;
  position: relative;
}

.customer_title {
  font-size: 4.125rem;
  line-height: 5.06rem;
  color: var(--white);
  font-family: "montserrat", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 10%;
  margin: 0;
  max-width: 80vw;
  overflow-x: hidden;
}

@media screen and (max-width: 62rem) {
  .page_wrapper_container {
    margin-top: 40px;
    position: relative;
  }
}

@media screen and (max-width: 48rem) {
  .customer_title {
    font-size: 2.5rem;
    left: 5%;
    top: 30%;
    margin: 0;
  }
  .page_wrapper_container {
    margin-top: 40px;
  }
}

@media screen and (max-width: 24rem) {
  .customer_title {
    font-size: 2.5rem;
    left: 5%;
    margin: 0;
  }
}
