.complete_tattoo_container {
  width: 379px;
  font-size: 1.125rem;
  line-height: 1.5;
  font-family: "montserrat", sans-serif;
  font-weight: 300;
}

.complete_tattoo_container .lg_green_button {
  margin-top: 18px;
}


@media screen and (max-width: 62rem) {
  .complete_tattoo_container {
    width: 341px;
  }
}

@media screen and (max-width: 48rem) {
  .complete_tattoo_container {
    width: 296px;
  }
}

@media screen and (max-width: 24rem) {
  .complete_tattoo_container {
    width: 250px;
  }
}
