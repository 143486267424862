.footer {
  background: var(--black);
  color: var(--white);
  padding: 2.94rem 0;
}

.footer_links_main {
  margin: 0;
  list-style: none;
  display: flex;
}

.footer_links {
  color: var(--white);
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 600;
  font-family: "montserrat", sans-serif;
  padding: 0 1.56rem;
}
.footer_links:hover,
.footer_links:focus {
  text-decoration: underline;
  filter: brightness(0.85);
}

.footer_logo {
  margin-left: auto;
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 300;
  font-family: "montserrat", sans-serif;
  padding: 0 1.56rem;
}

@media screen and (max-width: 62rem) {
}

@media screen and (max-width: 48rem) {
  .footer_links_main {
    flex-direction: column;
    margin-left: 30px;
  }
  .footer_links {
    padding: 0.5rem 0;
  }
}
