/*css variables, color theme*/
:root {
  --green: #37c452;
  --black: #262626;
  --grey: #858585;
  --red: rgb(255, 90, 90);
  --white: #ffffff;
  --bg_grey: #fafafa;
  --grey_label: #9a9a9a;
  --modal_background: #00000033;
  --tag_color: #909090;
  --profile_border: #d6d6d6;
}
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

button {
  color: var(--black);
}
/* remember to define focus styles! */
:focus {
  outline: 0;
}
body {
  line-height: 1.5;
  color: black;
  background: #ffffff;
}

@media screen and (max-width: 48rem) {
  body {
    max-width: 100%;
    overflow-x: hidden;
  }
}

ol,
ul {
  list-style: none;
}
body {
  margin: 0;
  font-family: Montserrat, "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black);
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
