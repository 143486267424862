.reset_password {
  width: 100%;
}

.reset_instructions {
  margin: 0;
}

.resend_button {
  background: url("../../img/buttons/btn_green_outline_large.svg");
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  width: 379px;
  height: 51px;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--green);
}

.resend_button:hover {
  filter: brightness(0.85);
}

.reset_password_button,
.resend_code_button {
  margin: 0.687rem 0;
}

@media screen and (max-width: 48rem) {
  .resend_button {
    background-size: contain;
    background-repeat: no-repeat;
    width: 296px;
    height: 45.9px;
  }
}

@media screen and (max-width: 24rem) {
  .resend_button {
    max-width: 250px;
  }
}
