.profile_password{
    background-color: #EDEDED;
    border-radius: 6px;
    height: 51px;
    border: none;
    padding-left: 1.5rem;
    font-size: 1rem;
    letter-spacing: 0.96px;
    line-height: 2.375rem;
    color: var(--black);
    width: 100%;
    max-width: 379px;
}

.profile_password::placeholder{
    color: var(--black) !important;
}