.customer_history_content_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.history_page_numbers {
  margin-top: 40px;
  margin-bottom: 51px;
  margin-right: 10vw;
  align-self: flex-end;
}

.history_page_numbers ul {
  display: flex;
  align-items: center;
}

.history_page_numbers ul li {
  line-height: 1.5;
  font-weight: 600;
  font-family: "montserrat", sans-serif;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px 0 8px;
  position: static;
}

.history_page_numbers ul li img {
  vertical-align: middle;
}

.ant-pagination-item-active {
  background: var(--green);
  border-radius: 6px;
  width: 40px;
  height: 40px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-pagination-item-link {
  background: var(--white);
  border: none;
}

@media screen and (max-width: 48rem) {
  .history_page_numbers {
    align-self: center;
    display: flex;
    justify-content: center;
    width: 100vw;
    margin-right: 0;
  }
}
