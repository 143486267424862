.image_editor_background_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--modal_background);
  z-index: 90;
}

.image_editor_container {
  position: fixed;
  background: var(--white);
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  box-shadow: 0px 0px 16px #00000029;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  z-index: 90;
}

.image_editor_title {
  background: var(--black);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.125rem 1.875rem;
  border-radius: 10px 10px 0 0;
}

.image_editor_title h3 {
  font-size: 1.125rem;
  text-transform: uppercase;
  font-family: "montserrat", sans-serif;
  line-height: 1.375rem;
  font-weight: 300;
  margin: 0;
}

.image_editor_title img {
  color: var(--white);
}

.image_editor_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image_editor_controls {
  margin: 0 1rem 2rem 1rem;
  display: flex;
  flex-direction: column;
}

.preview_image {
  color: var(--black);
  font-size: 1.125rem;
  text-transform: uppercase;
  font-family: "montserrat", sans-serif;
  line-height: 1.375rem;
  font-weight: 300;
  margin-top: 14px;
}

.image_editor_content_preview {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image_editor_content_preview img {
  width: 50%;
}

.image_range {
  -webkit-appearance: none;
  -moz-apperance: none;
  height: 6px;
  background-color: #b6b6b6;
  outline-color: transparent;
}
.image_range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-apperance: none;
  width: 16px;
  height: 16px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  background-color: var(--green);
  overflow: visible;
}

.image_editor_button,
.image_file_input {
  background: url("../../img/buttons/btn_green_large.svg");
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  width: 379px;
  height: 51px;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--white);
  margin-top: 14px;
}

.image_editor_button:hover {
  filter: brightness(0.85);
}

@media screen and (max-width: 48rem) {
  .image_editor_button, .image_file_input {
    background-size: contain;
    background-repeat: no-repeat;
    width: 341px;
    height: 45.9px;
  }
  .image_editor_container{
    width: 90%;
  }
  .image_editor_content_preview {
    width: 100%;
  }
}

@media screen and (max-width: 24rem) {
  .imgage_editor_content_preview canvas{
    width: 90%
  }
  .image_editor_button, .image_file_input {
    max-width: 250px;
  }
  .image_editor_container{
    width: 90%;
  }
}
