.password_icon {
    height: 24px;
    width: 24px;
    position: absolute;
    top: 25.5px;
    left: 90%;
    z-index: 2;
    color: var(--white);
  }

  .password_wrapper {
    display: flex;
    position: relative;
    width: 100%;
  }

  @media screen and (max-width: 24rem) {
    .password_icon{
      position: absolute;
      top: 25.5px;
      left: 85%;
    }
  }