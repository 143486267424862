.client_details_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.client_details_header {
  width: 100%;
}

.client_details_header .client_card_status {
  margin: 0;
}

.client_details_header_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.client_details_header_details button {
  margin-top: 0;
  margin-right: 130px;
}

.client_details_header_text {
  display: flex;
}

.client_details_title {
  font-size: 0.875rem;
  line-height: 1.5;
  color: rgb(38, 38, 38, 0.4);
}

.client_details_date {
  font-size: 0.875rem;
  line-height: 1.5;
  color: rgb(38, 38, 38, 0.75);
}

.client_details_history_container {
  margin-top: 3.56rem;
  width:80vw;
}
.client_details_history_title {
  font-size: 1.125rem;
  line-height: 1.5;
  font-weight: bold;
}

.client_details_history_content {
  margin-top: 18px;
}

.client_details_history_item {
  background: rgb(237, 237, 237, 0.3);
  border-radius: 6px;
  padding: 12.5px 9px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 9px;
}

.client_detail_payment_description {
  color: var(--black);
  font-weight: bold;
}

.client_details_history_item_no_content p {
  font-size: 1.125rem;
  line-height: 1.5;
  color: rgb(38, 38, 38, 0.4);
}

@media screen and (max-width: 82.5rem) {
  .client_details_container {
    margin-top: 3.5rem;
  }
}
@media screen and (max-width: 62rem) {
  .client_details_header_text {
    flex-direction: column;
  }
  .client_details_header_details {
    flex-direction: column;
  }
  .client_details_header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .client_details_header_text .client_card_details {
    margin-bottom: 20px;
  }
  .client_details_header_details button {
    margin-top: 20px;
  }
}

@media screen and (max-width: 48rem) {
  .client_details_container {
    width: 90vw;
  }
}
