.error_boundary_container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  margin-top:100px;
  align-items: center;
}

.error_boundary_message {
  font-family: "montserrat", sans-serif;
  font-size: 2rem;
  display:flex;
  flex-direction:column;
  align-items:center;
}

.error_boundary_message button{
    background-color: var(--green);
    padding: 10px;
    border-radius: 10px;
    width: 150px;

}

.error_boundary_container details{
    margin-top:20px;
}
