.edit_description{
    background-color: #EDEDED;
    border-radius: 6px;
    height: 150px;
    border: none;
    padding-left: 1.5rem;
    font-size: 1rem;
    letter-spacing: 0.96px;
    line-height: 1.5rem;
    color: var(--black);
    width: 100%;
    max-width: 379px;
    font-family: 'roboto', sans-serif;
}

.text_count{
    font-size: 0.875rem;
    line-height: 1.188rem;
    margin:0;
    align-self: flex-end;
    margin-bottom:10px;
}