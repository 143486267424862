.individual_loan_details_container {
  display: flex;
  justify-content: center;
  width: 80vw;
  margin: 27px 0 47px 0;
}

.individual_loan_details_left {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 267px;
  margin-right: 44px;
}

.individual_circle_requested_amount {
  font-size: 0.875rem;
  line-height: 1.5;
  color: rgb(38, 38, 38, 0.4);
}

.individual_loan_card_details {
  margin: 20px 0 10px 20px;
  max-width: 248px;
}

.individual_loan_cicle_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 350px;
  max-height: 350px;
  height: 100%;
}

.view_contract_button {
  background: url("../../../img/buttons/btn_green_med.svg");
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
  width: 227px;
  min-height: 51px;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--white);
  margin-top: 20px;
  margin-left: 20px;
}

.payment_text_container {
  display: flex;
  flex-direction: column;
}

.loan_details_history_title {
  font-size: 1.125rem;
  line-height: 1.5;
  font-weight: bold;
  display: flex;
}

.loan_details_header_details {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
}

.loan_details_header_details .lg_green_button {
  margin-top: 41px;
}
.loan_details_header_details .loan_approve_message {
  width: 100%;
}

.loan_details_history_title h2 {
  margin-right: 56px;
}

.loan_details_history_tab_inactive {
  color: rgb(38, 38, 38, 0.4);
}
.loan_details_history_no_content {
  height: 351px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(237, 237, 237, 0.3);
  border-radius: 6px;
}

.loan_details_history_no_content p {
  margin-top: 42px;
  color: #909090;
  font-weight: bold;
}

.nextPayment {
  text-transform: uppercase;
}

.loan_details_history_item {
  background: rgb(237, 237, 237, 0.3);
  border-radius: 6px;
  padding: 12.5px 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 9px;
  font-size: 1rem;
  line-height: 1.5;
}

.loan_detail_payment_date {
  color: rgb(38, 38, 38, 0.4);
}

.loan_detail_payment_description {
  color: var(--black);
}

.loan_detail_payment_amount {
  font-weight: bold;
}

.loan_details_history_main_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.payment_details_button {
  background: none;
  border: none;
  color: var(--green);
  font-weight: bold;
}

.loan_details_missed_payment_item {
  background: var(--red);
  border-radius: 6px;
  padding: 12.5px 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 9px;
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
}

.loan_detail_missed_payment {
  color: var(--white);
}
.bold {
  font-weight: bold;
}

.make_missed_payment {
  position: absolute;
  right: -35%;
}
.loan_details_history_container {
  margin-top: 3.56rem;
  width: 100%;
}

.individual_loan_details_right {
}

@media screen and (max-width: 102rem) {
  .individual_loan_details_container {
    width: 65vw;
  }
}

@media screen and (max-width: 90rem) {
  .make_missed_payment {
    right: 1%;
    top: -70px;
  }
}
@media screen and (max-width: 62rem) {
  .individual_loan_details_container {
    flex-direction: column;
    align-items: center;
    width: 100vw;
    padding: 0 20px;
  }

  .individual_loan_details_left {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    margin: 0;
  }
  .individual_loan_cicle_container {
    min-width: 200px;
  }
  .loan_details_header_details {
    align-items: center;
  }
  .loan_details_header_details .client_details_header_text {
    flex-direction: row;
  }
}

@media screen and (max-width: 48rem) {
  .individual_loan_details_left {
    flex-direction: column;
  }
  .loan_details_header_details .client_details_header_text {
    flex-direction: column;
  }

  .individual_loan_details_right {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .make_missed_payment {
    right: 20px;
    top: -140px;
  }
  .loan_details_history_title {
    margin-top: 2rem;
  }
  .loan_details_history_item {
    flex-direction: column;
  }
  .loan_details_history_main_details {
    width: 95%;
  }
  .loan_detail_missed_payment {
    max-width: 150px;
  }
  .payment_details_button {
    margin-top: 10px;
  }
}
