.contact_container {
  display: flex;
  justify-content: center;
}

.contact_content {
  margin: 50px 0;
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact_form {
  display: flex;
  flex-direction: column;
  width: 56.2%;
}

.contact_sender {
  display: flex;
  justify-content: space-between;
}

.contact_form_input {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 0.625rem;
  flex-grow: 1;
}

.contact_form label {
  font-size: 1rem;
  letter-spacing: 0.96px;
  line-height: 2.375rem;
  color: var(--grey_label);
  font-family: "montserrat", sans-serif;
  font-weight: 300;
}

.contact_sender input {
  background-color: #ededed;
  height: 59px;
  border: 1px solid var(--black);
  border-radius: 4px;
  background-color: transparent;
  padding-left: 1.68rem;
}
.contact_form_text {
  display: flex;
  flex-direction: column;
  margin: 2.06rem 0 0 0.625rem;
}

.contact_form textarea {
  background-color: #ededed;
  width: 100%;
  height: 147px;
  border: 1px solid var(--black);
  border-radius: 4px;
  background-color: transparent;
  padding-left: 1.68rem;
  padding-top: 1.06rem;
}

.contact_sender input,
.contact_sender input::placeholder {
  background-color: #ededed;
  font-size: 1.31rem;
  letter-spacing: 1.26px;
  line-height: 2.375rem;
  font-family: "montserrat", sans-serif;
  font-weight: 300;
  color: var(--black);
}

.contact_form textarea,
textarea::placeholder {
  background-color: #ededed;
  border-radius: 6px;
  color: var(--black);
  font-size: 1.31rem;
  letter-spacing: 1.26px;
  line-height: 2.375rem;
  font-family: "montserrat", sans-serif;
  font-weight: 300;
}

@media screen and (max-width: 62rem) {
  .contact_sender {
    flex-direction: column;
  }
  .contact_content {
    background-size: auto 70%;
  }
  .contact_form_input {
    margin-top: 2.06rem;
  }
  .contact_form {
    width: 90%;
  }
}

@media screen and (max-width: 48rem) {
  .contact_content {
    width: 90vw;
  }
  .contact_sender input,
  input::placeholder {
    height: 40px;
    font-size: 1rem;
    line-height: 2rem;
  }
  .contact_form textarea,
  textarea::placeholder {
    font-size: 1rem;
    line-height: 2rem;
  }
  .contact_button {
    align-self: center;
    margin-top: 5rem;
    margin-bottom: 6rem;
  }
}
