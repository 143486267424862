.profile_custom_input{
    width: 100%;
    margin: 5px 0;
}

.profile_custom_input_ele{
    background-color: #EDEDED;
    border-radius: 6px;
    height: 51px;
    border: none;
    padding-left: 1.5rem;
    font-size: 1rem;
    letter-spacing: 0.96px;
    line-height: 2.375rem;
    color: var(--black);
    width: 100%;
    max-width: 379px;
}

.profile_custom_error{
    border: 2px solid var(--red);
}

.profile_custom_input input::placeholder{
    color: var(--black);
    opacity: 0.28;
}

.profile_error_message{
    font-size: 0.875rem;
    line-height: 1.188rem;
    color: var(--red);
    margin:0;
}

