.calculator_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 686px;
}
.calculator_inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.term_length_select {
  margin: 0.687rem 0;
  background-color: #ededed;
  border-radius: 6px;
  height: 51px;
  border: none;
  padding-left: 1.5rem;
  color: var(--black);
  width: 333px;
  font-size: 1.31rem;
  letter-spacing: 1.08px;
  line-height: 1.8;
  font-family: "roboto", sans-serif;
  font-weight: normal;
}

.term_length_select:focus {
  color: var(--black);
  font-size: 1.125rem;
  letter-spacing: 1.08px;
}

.term_length_select option {
  font-family: "roboto", sans-serif;
  font-size: 1.125rem;
  letter-spacing: 1.08px;
  line-height: 1.8;
}
.term_length_select option:disabled {
  opacity: 0.5;
}

#calculator_input,
.discount_input {
  background-color: #ededed;
  border-radius: 6px;
  height: 51px;
  border: none;
  padding-left: 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.96px;
  line-height: 2.375rem;
  color: var(--black);
  width: 100%;
}

#calculator_input {
  width: 333px;
  margin-left: 20px;
  background-color: #ededed;
}
#calculator_input:focus {
  background-color: #ededed;
  border: 2px solid black;
}

#calculator_input::placeholder,
.discount_input::placeholder {
  color: var(--black);
  opacity: 0.28;
}

.calculator_results_container {
  display: flex;
  flex-direction: column;
  background-color: #ededed;
  border-radius: 6px;
  width: 100%;
  margin-top: 31px;
  padding: 16px;
}

.calculated_values {
  display: flex;
  justify-content: space-between;
}
.calculated_values p {
  font-size: 1rem;
  line-height: 1.5;
}

.calculated_values_name {
  opacity: 0.75;
}

.calculated_values_value {
  font-weight: bold;
}

.calculated_total {
  padding: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 1.25rem;
  line-height: 1.5;
  opacity: 0.75;
}

.total_message {
  font-weight: bold;
  opacity: 1;
}
.calc_error {
  align-self: flex-end;
  color: var(--red);
  max-width: 379px;
  font-size: 0.875rem;
  line-height: 1.188rem;
}

.apply_for_loan_contacts {
  justify-content: center;
}

@media screen and (max-width: 66rem) {
  .apply_for_loan_contacts {
    flex-direction: column;
  }
}

@media screen and (max-width: 48rem) {
  .calculator_inputs {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .calculator_input {
    margin: 0;
  }
  .calculated_total {
    padding: 16px 0 16px 0;
  }
  #calculator_input {
    margin-left: 0;
  }
  .total_message {
    font-size: 1.2rem;
    text-align: end;
    padding-left: 10px;
  }
  .apply_for_loan_contacts {
    justify-content: center;
    text-align: center;
    overflow:auto;
    overflow-wrap: break-word;
  }
  .apply_for_loan_contacts p {
    overflow-wrap: break-word;
    line-height: 1.5;
    max-width: 85vw;
  }
  .term_length_select {
    width: 333px;
  }
}

@media screen and (max-width: 24rem) {
  .term_length_select {
    width: 250px;
  }
  .calculator_inputs input {
    width: 250px;
  }
  .apply_for_loan_contacts {
    flex-direction: column;
  }
  #calculator_input {
    width: 250px;
  }
}
