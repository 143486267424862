.customer_profile_subtitles {
  font-size: 1rem;
  line-height: 1.188rem;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "montserrat", sans-serif;
}
.content_container{
  width: 60vw;
  min-height: 31vh;
}
.content_container p{
  margin: 10px 0 10px;
}

.customer_profile_details {
  margin: 5px 0;
  font-size: 1rem;
  line-height: 1.312rem;
  font-weight: 300;
}

.customer_password_wrapper,
.customer_banking_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12.5px;
  padding-bottom: 1.125rem;
  border-bottom: 1px solid var(--profile_border);
}

.customer_password_header {
  height: 51px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.customer_secondary_title {
  font-size: 4.125rem;
  line-height: 5.06rem;
  color: var(--white);
  font-family: "montserrat", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
}

@media screen and (max-width: 62rem) {
  .customer_secondary_title {
    top: 20%;
    left: 10%;
  }
}

@media screen and (max-width: 48rem) {
  .customer_password_wrapper,
  .customer_banking_wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .edit_banking_button_container {
    align-self: flex-start;
  }
  .customer_secondary_title {
    font-size: 3.5rem;
    line-height: 4rem;
    left: 5%;
    margin: 0;
  }
  .content_container{
    width: 95vw;
  }
}

@media screen and (max-width: 24rem) {
  .customer_secondary_title {
    font-size: 2.5rem;
    line-height: 3rem;
    left: 5%;
    margin: 0;
  }
}
