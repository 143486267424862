.lg_green_button {
  background: url("../../../img/buttons/btn_green_large.svg");
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  width: 379px;
  height: 51px;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--white);
}

.lg_green_button:hover {
  filter: brightness(0.85);
}

.lg_green_button:disabled{
  filter: brightness(0.85);
}

@media screen and (max-width: 62rem) {
  .lg_green_button {
    background-size: contain;
    background-repeat: no-repeat;
    width: 296px;
    height: 45.9px;
  }
}

@media screen and (max-width: 24rem) {
  .lg_green_button {
    max-width: 250px;
  }
}
