.approve_loan_container {
  width: 379px;
}

.approve_loan_container p {
  margin-bottom: 14px;
  font-size: 1.125rem;
  line-height: 1.5;
}
.approve_loan_modal_details {
  background-color: #ededed;
  border-radius: 6px;
  padding: 24px 16px 10px 16px;
}
.approve_loan_modal_details p {
  font-size: 1rem;
  line-height: 1.5;
  color: rgb(38, 38, 38, 0.75);
}
.approve_loan_modal_details p span {
  float: right;
  font-weight: bold;
}

.approve_loan_modal_details hr {
  margin-top: 0;
  margin-bottom: 14px;
  background-color: rgb(38, 38, 38, 0.3);
}

.approve_loan_container button {
  margin-top: 28px;
}


@media screen and (max-width: 62rem) {
  .approve_loan_container {
    width: 341px;
  }
}

@media screen and (max-width: 48rem) {
  .approve_loan_container {
    width: 296px;
  }
}

@media screen and (max-width: 24rem) {
  .approve_loan_container {
    width: 250px;
  }
}
