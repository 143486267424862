.apply_for_loan_container {
  margin-bottom: 50px;
  text-align: center;
}
.apply_for_loan_container img {
  border-radius: 10px;
}

.apply_for_loan_container h2 {
  font-size: 1.375rem;
  line-height: 1.5;
  font-family: "montserrat", sans-serif;
  margin: 13px 0 6px 0;
}

.apply_for_loan_container p {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 300;
  opacity: 0.5;
  text-align: center;
}

.apply_for_loan_contacts {
  display: flex;
  align-items: center;
  max-width:90vw;
}

@media screen and (max-width: 48rem) {
  .apply_for_loan_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }
  .apply_for_loan_container img {
    width: 90%;
    height: auto;
  }
}
