.decline_loan_container {
  width: 379px;
}

.decline_loan_container p {
  font-size: 1.125rem;
  line-height: 1.5;
}

.decline_modal_details {
  margin: 8px 0 31px 0;
}

.decline_modal_details p {
  font-size: 1rem;
  line-height: 1.5;
  color: rgb(38, 38, 38, 0.75);
}

.decline_modal_details p span {
  float: right;
  font-weight: bold;
  color: var(--black);
}

.lg_decline {
  background: url("../../../img/buttons/btn_decline_large.svg");
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  width: 379px;
  height: 51px;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--white);
}

.lg_decline:hover {
  filter: brightness(0.85);
}

@media screen and (max-width: 62rem) {
  .lg_decline {
    background-size: contain;
    background-repeat: no-repeat;
    width: 341px;
    height: 45.9px;
  }
  .decline_loan_container {
    width: 341px;
  }
}

@media screen and (max-width: 48rem) {
  .decline_loan_container {
    width: 296px;
  }
}

@media screen and (max-width: 24rem) {
  .lg_decline {
    max-width: 250px;
  }
  .decline_loan_container {
    width: 250px;
  }
}
