.artist_card_container {
  width: 686px;
  background: var(--black);
  border-radius: 10px;
  margin-top: 14px;
}
.artist_card_banner_img img {
  height: 253px;
  border-radius: 10px 10px 0 0;
}
.artist_card_content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 18px;
}
.artist_card_text {
  color: var(--white);
  font-size: 1rem;
  line-height: 1.5;
  font-family: "montserrat", sans-serif;
  font-weight: 300;
  text-transform: capitalize;
}
.artist_card_title {
  font-size: 1.375rem;
  line-height: 1.5;
  font-family: "montserrat", sans-serif;
  text-transform: capitalize;
}
.artist_card_secondary_info {
  margin-top: 8px;
  height: 22px;
}

.artist_card_social {
  margin-top: 14px;
  font-weight: normal;
  text-transform: none;
  height: 16px;
}

.med_green_button {
  background: url("../../../img/buttons/btn_green_med.svg");
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  width: 227px;
  height: 51px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--white);
  margin-top: ;
}

.med_green_button:hover {
  filter: brightness(0.85);
}

@media screen and (max-width: 48rem) {
  .artist_card_container {
    width: 350px;
    background: var(--black);
    border-radius: 10px;
    margin-top: 14px;
  }

  .artist_card_banner_img img {
    width: 100%;
    height: auto;
    border-radius: 10px 10px 0 0;
  }
  .artist_card_content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 18px;
  }
  .med_green_button {
    align-self: center;
    margin-top: 30px;
  }
  .artist_card_social {
    height: auto;
  }
}
