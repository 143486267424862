.redeem_amount_container {
  width: 379px;
}

.redeem_amount_available {
  margin: 9px 0 19px 0;
}
.redeem_amount_container p {
  line-height: 1.5;
}
.redeem_amount_container .lg_green_button {
  margin-top: 18px;
}
.redeem_amount_available span {
  float: right;
  font-weight: bold;
}

.confirm_amount_container p {
  margin-bottom: 14px;
}

.confirm_amount_container p span {
  float: right;
  font-weight: bold;
}

.confirm_amount_container hr {
  margin-top: 0;
  margin-bottom: 14px;
}

.confirm_amount_container button {
  margin-top: 28px;
}

.redeem_amount_container #calculator_input {
  width: 379px;
  margin-left: 0;
}


@media screen and (max-width: 62rem) {
  .redeem_amount_container,
  .redeem_amount_container #calculator_input {
    width: 341px;
  }
}

@media screen and (max-width: 48rem) {
  .redeem_amount_container,
  .redeem_amount_container #calculator_input {
    width: 296px;
  }
}

@media screen and (max-width: 24rem) {
  .redeem_amount_container,
  .redeem_amount_container #calculator_input {
    width: 250px;
  }
}
