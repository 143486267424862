.pdfviewer_wrapper {
  isolation: isolate;
}

.pdfviewer_wrapper p {
  margin: 0 10px 0 10px;
}

.accept_contract_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  position: sticky;
  width: 100%;
  background-color: var(--white);
  bottom: 0;
  justify-content: center;
  margin-top: 15px;
  padding-bottom: 10px;
}

.contract_signature_title {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  margin-top: 10px;
}

.contract_signature_title h4 {
  position: absolute;
  z-index: 1;
}

.accept_contract_container .lg_green_button {
  margin-top: 10px;
}
.contract_signature_title canvas {
  height: 90px;
  width: 350px;
}

@media screen and (max-height: 44rem) and (min-width: 62rem) {
  .accept_contract_container {
    bottom: -100px;
  }
}

@media screen and (max-width: 48rem) {
  .contract_signature_title canvas {
    height: 80px;
    width: 200px;
  }
}
