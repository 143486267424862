.loan_card_container {
  display: flex;
  align-items: flex-start;
  width: 80vw;
  height: 23.7rem;
  border: 1px solid var(--black);
  border-radius: 10px;
  padding: 50px 40px;
  margin: 20px 0 20px 0;
  background-color: var(--black);
  color: var(--white);
  background-image: url("../../../img/background_tattoo/background_tattoo.svg");
  background-repeat: no-repeat;
  background-position: right;
}

.loan_card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 19px;
}

.loan_card_header .loan_card_title {
  margin-bottom: 0;
}

.loan_cicle_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  min-width: 350px;
  height: 100%;
}

.loan_circle_size {
  height: 267.79px;
  width: 267.79px;
}

.circle_amount_used {
  padding-top: 20px;
  font-size: 2.5rem;
  line-height: 43px;
  font-weight: bold;
  font-family: "roboto", sans-serif;
}

.circle_requested_amount {
  font-size: 0.875rem;
  line-height: 1.5;
  color: rgb(255, 255, 255, 0.75);
}

.loan_card_status {
  font-size: 1.125rem;
  line-height: 2;
  color: var(--green);
  font-family: "roboto", sans-serif;
  font-weight: bold;
  letter-spacing: 1.08px;
  margin-bottom: 16px;
}

.loan_card_in_collections {
  font-size: 1.125rem;
  line-height: 2;
  color: var(--red);
  font-family: "roboto", sans-serif;
  font-weight: bold;
  letter-spacing: 1.08px;
  margin-bottom: 16px;
}

.lg_button_inactive {
  background-color: #454545;
  border-radius: 6px;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  width: 379px;
  height: 51px;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
  color: rgb(255, 255, 255, 0.21);
}

.loan_details_container {
  height: 100%;
}

.loan_approved_text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.loan_approve_message {
  margin-top: 30px;
  font-size: 1.125rem;
  line-height: 1.5;
  letter-spacing: 1.08px;
  width: 80%;
}

.loan_approve_message strong {
  font-weight: bold;
}

.button_view_more {
  align-self: flex-end;
  background: transparent;
  border: none;
  color: var(--white);
  text-transform: uppercase;
  font-family: "montserrat", sans-serif;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.approve_loan_buttons {
  display: flex;
}

.subloan_waiting_approval_loan_details_text_container {
  display: flex;
  justify-content: space-between;
}
.subloan_waiting_approval_loan_details_text_container .loan_details {
  margin: 0;
}

.subloan_waiting_approval_loan_approved_text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.loan_details_title_missed_payment {
  margin-top:30px;
  font-size: 0.875rem;
  line-height: 1.5;
  color: var(--red);
}

@media screen and (max-width: 62rem) {
  .loan_card_container {
    width: 95vw;
    padding: 20px 10px;
    flex-direction: column;
    height: auto;
    align-items: center;
  }
  .lg_button_inactive {
    background-size: contain;
    background-repeat: no-repeat;
    width: 341px;
    height: 45.9px;
  }
  .loan_card_header {
    flex-direction: column;
    align-items: flex-start;
  }
  .approve_loan_buttons button {
    margin-right: 20px;
  }
  .loan_cicle_container {
    padding: 0;
    justify-content: center;
    max-height: 200px;
    max-width: 200px;
  }
  .loan_circle_size {
    max-height: 200px;
    max-width: 200px;
  }

  .subloan_waiting_approval_loan_details_text_container {
    display: flex;
    margin-right: 20px;
  }
}

@media screen and (max-width: 48rem) {
  .loan_card_container {
    width: 95vw;
    padding: 20px 10px;
    flex-direction: column;
    height: auto;
    align-items: center;
  }
  .loan_card_header {
    align-items: center;
  }
  .approve_loan_buttons {
    flex-direction: column;
  }
  .approve_loan_buttons button {
    margin-right: 0;
  }
  .subloan_waiting_approval_loan_approved_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .subloan_waiting_approval_loan_details_text_container {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
  }
  .subloan_waiting_approval_loan_details_text_container .loan_details {
    margin-top: 20px;
  }
  .loan_details_title_missed_payment {
    text-align:center;
    margin-top:30px;
    font-size: 0.875rem;
    line-height: 1.5;
    color: var(--red);
  }
}
@media screen and (max-width: 24rem) {
  .lg_button_inactive {
    max-width: 250px;
  }
}
