.forgot_password_button {
  margin-top: 2.25rem;
}
.forgot_password_secondary_buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1.48rem;
}
.forgot_password_secondary_buttons button {
  background: transparent;
  border: none;
  outline: none;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.19rem;
  font-family: "montserrat", sans-serif;
  font-weight: 600;
  padding: 0;
}

.forgot_password input {
    width: 100%;
}

.forgot_instructions{
    margin: 0;
}

