.notification_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 42px 0 30px 0;
}

.notification_content_container {
  width: 60vw;
  height: 5rem;
  padding: 12px 32px 19px 24px;
  background: rgb(237, 237, 237, 0.3);
  border-radius: 6px;
  margin-bottom: 9px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.notification_title_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.notification_title_container h3 {
  color: var(--green);
  font-size: 0.875rem;
  line-height: 1.5;
  font-family: "roboto", sans-serif;
  font-weight: bold;
  text-transform: capitalize;
}

.notification_status {
  margin-left: auto;
  color: var(--black);
  font-size: 1rem;
  line-height: 1.5;
  font-weight: bold;
  font-family: "roboto", sans-serif;
  margin-right: 15px;
}

.notification_title_container p {
  font-size: 0.75rem;
  line-height: 1.5;
  opacity: 0.5;
}
.notification_page_numbers {
  margin-top: 80px;
  align-self: flex-end;
  margin-right: 20vw;
}

.notification_page_numbers ul {
  display: flex;
  align-items: center;
}

.notification_page_numbers ul li {
  line-height: 1.5;
  font-weight: 600;
  font-family: "montserrat", sans-serif;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px 0 8px;
  position: static;
}

.notification_page_numbers ul li img {
  vertical-align: middle;
}

.unread {
  background: #edf9ee;
}

.unread .notification_status {
  color: var(--green);
}

@media screen and (max-width: 62rem) {
  .notification_content_container {
    width: 80vw;
  }
  .notification_page_numbers {
    align-self: center;
    margin-right: 0;
  }
}

@media screen and (max-width: 48rem) {
  .notification_content_container {
    width: 95vw;
    height: auto;
  }
  .notification_page_numbers {
    width: 100vw;
    display: flex;
    justify-content: center;
  }
}

