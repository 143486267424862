.make_payments_container {
  max-width: 379px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.make_payments_header {
  width: 100%;
  margin-bottom: 10px;
}
.first_column {
  width: 40%;
  padding: 0 0 0 15px;
}

.make_payments_header th {
  font-size: 0.875rem;
  line-height: 1.5;
  color: rgb(38, 38, 38, 0.4);
}

.make_payments_subloan_details {
  width: 100%;
  background: #ededed;
  border-radius: 6px;
  margin-bottom: 9px;
}
.make_payments_subloan_details td {
  height: 50px;
  vertical-align: middle;
  padding: 0px 15px;
}

.make_payments_select {
  width: 24px;
  height: 24px;
  background-image: url("../../../img/radio/radio_empty.svg");
  border: none;
}

.make_payments_select_active {
  width: 24px;
  height: 24px;
  background-image: url("../../../img/radio/radio_fill.svg");
  border: none;
}

.make_payments_bold {
  font-weight: bold;
  text-align: center;
}

.make_payments_container .lg_green_button {
  margin-top: 30px;
}

