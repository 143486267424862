.notification_dropdown_background_container {
 
}

.notification_dropdown_container {
  position: absolute;
  background: var(--white);
  height: auto;
  width: 19rem;
  top: 8vh;
  right: 12vw;
  border-radius: 6px;
  box-shadow: 0px 0px 16px #00000029;
  z-index: 90;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y:auto;
}

.notifications {
  width: 100%;
  background-color: var(--white);
}

.notification_dropdown_content_container {
  padding: 10px 24px 2px 15px;
}

.notification_dropdown_content_container_unread {
  background-color: rgb(55, 196, 82, 0.1);
}
.notification_dropdown_title_container {
  display: flex;
  justify-content: space-between;
}

.notification_dropdown_title_container h3 {
  color: var(--green);
  font-size: 0.875rem;
  line-height: 1.5;
  font-family: "roboto", sans-serif;
  font-weight: bold;
  text-transform: capitalize;
}

.notification_dropdown_title_container p {
  font-size: 0.75rem;
  line-height: 1.5;
  opacity: 0.5;
}

.notification_dropdown_message {
  font-size: 0.75rem;
  line-height: 1.5;
  text-transform: none;
}

.notifications_link {
  align-self: center;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--green);
  font-family: "montserrat", sans-serif;
  font-weight: 600;
  margin: 10px 0 11px 0;
}
