.artist_header {
  background: url("../../../img//banner_eye/banner_eye@2x.png");
  background-position: center;
  background-size: cover;
  height: 30vh;
  position: relative;
  width: 100%;
}

.artist_title {
  font-size: 4.125rem;
  line-height: 1.5;
  color: var(--white);
  font-family: "montserrat", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 120px;
  max-width: 100%;
  width: 90%;
  margin: 0;
}

.not_verified {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4.5rem;
  margin-bottom: 10.5rem;
  min-height: 13vh;
  max-width: 100%;
}

.not_verified img {
  width: 403.9px;
}

.not_verified_content {
  margin-top: 2.375rem;
  text-align: center;
  background: #ededed;
  max-width: 60%;
  padding: 1.9rem;
  border-radius: 6px;
}

.not_verified_content p {
  font-size: 1rem;
  line-height: 1.5;
  font-family: "montserrat", sans-serif;
  font-weight: bold;
}
.not_verified_content a {
  color: var(--black);
}

.not_verified_content a:hover,
.not_verified_content a:focus {
  text-decoration: none;
}
.status {
  height: 51px;
  background: var(--white);
  opacity: 0.6;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10%;
  right: 15%;
  padding: 1.5rem;
}
.status p {
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.8;
  letter-spacing: 1.08px;
  font-weight: bold;
  color: var(--black);
  opacity: 1;
}

.status_verified p,
.status_declined p {
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.8;
  letter-spacing: 1.08px;
  font-weight: bold;
  color: var(--white);
  opacity: 1;
}

.status img,
.status_verified img,
.status_declined img {
  margin-right: 0.5rem;
}

.status_declined img {
  border: 3px solid var(--white);
  border-radius: 50%;
}

.status_verified {
  height: 51px;
  background: var(--green);
  opacity: 0.8;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10%;
  right: 15%;
  padding: 1.5rem;
}

.status_declined {
  height: 51px;
  background: var(--red);
  opacity: 0.8;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10%;
  right: 15%;
  padding: 1.5rem;
}

@media screen and (max-width: 62rem) {
  .not_verified_content {
    width: 50%;
  }
}

@media screen and (max-width: 48rem) {
  .not_verified img {
    width: 50%;
  }
  .not_verified_content {
    width: 80%;
  }
  .artist_title {
    font-size: 2.5rem;
    line-height: 1.5;
    left: 5%;
    margin: 0;
  }
  .status_verified {
    top: 8%;
    right: 5%;
  }
}

@media screen and (max-width: 24rem) {
  .not_verified img {
    width: 50%;
  }
  .not_verified_content {
    width: 80%;
  }
  .artist_title {
    font-size: 2.5rem;
    left: 5%;
    margin: 0;
  }
}
