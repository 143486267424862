.customer_nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: white;
  position: sticky;
  top: 0;
  width: 100%;
  max-width: 100%;
  z-index: 80;
}

.customer_nav_active {
  color: var(--green);
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
}
.customer_nav_active:hover,
.customer_nav_active:focus {
  color: var(--green);
  opacity: 0.6;
}
.customer_navbar {
  width: 100%;
  height: 62px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 80;
}
.customer_nav_collapse {
  display: none;
}

.customer_nav_logo {
  margin-left: 12.5rem;
  margin-right: auto;
}
.customer_nav_logo img {
  width: 248.41px;
  height: auto;
}
.customer_nav_link {
  margin-right: 1.25em;
  text-transform: uppercase;
  overflow: hidden;
}
.customer_nav_link:last-child {
  margin-right: 12.5rem;
}

.customer_nav_icon_link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--black);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
}
.customer_nav_icon_link:hover,
.customer_nav_icon_link:focus {
  color: var(--black);
  opacity: 0.6;
}

.nav_menu_icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 5px;
  background: var(--white);
}
@media screen and (max-width: 86rem) {
  .customer_nav_logo {
    margin-left: 4rem;
  }
  .customer_nav_logo img {
    width: 200px;
  }
  .customer_nav_link:last-child {
    margin-right: 4rem;
  }
}

@media screen and (max-width: 66.3rem) {
  .customer_nav_collapse {
    padding: 0.5em 1em;
    display: inline-block;
    font-size: 1.25rem;
    background: var(--white);
    color: var(--black);
    cursor: pointer;
    outline: none;
    border: none;
    z-index: 80;
  }
  .customer_nav {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-end;
    z-index: 80;
    background-color: white;
  }
  .customer_nav_link {
    margin: 0.625em;
  }
  .customer_nav_logo {
    display: none;
  }
  .customer_navbar {
    height: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 99;
    width: fit-content;
    background: var(--white);
  }
  .show-nav {
    height: auto;
    background: var(--white);
    position: fixed;
    top: 4.5%;
  }
}
