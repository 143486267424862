.artist_sign_up .form_container {
  height: fit-content;
}

.artist_sign_up .form_inputs {
  margin: 20px 0 20px 0;
}

.province_select {
  margin: 0.687rem 0;
  background-color: rgb(0, 0, 0, 0.4);
  border-radius: 6px;
  height: 51px;
  border: none;
  padding-left: 1.5rem;
  color: var(--white);
  width: 100%;
  max-width: 379px;
  font-size: 1.125rem;
  letter-spacing: 1.26px;
  line-height: 2.375rem;
  font-family: "montserrat", sans-serif;
  font-weight: 300;
}

.province_select:focus {
  color: var(--white);
  font-size: 1.125rem;
  letter-spacing: 1.08px;
}

.province_select option {
  font-family: "roboto", sans-serif;
  font-size: 1.125rem;
  letter-spacing: 1.08px;
  line-height: 2.375rem;
}

.register_button {
  margin-top: 0.687rem;
}
.register_secondary_buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1.48rem;
}
.register_secondary_buttons button {
  background: transparent;
  border: none;
  outline: none;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.19rem;
  font-family: "montserrat", sans-serif;
  font-weight: 600;
  padding: 0;
}

.register_secondary_buttons button:hover,
.register_secondary_buttons button:focus {
  opacity: 0.6;
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: 48rem) {
  .form_input_content {
    align-items: center;
    justify-content: center;
  }
  .register_button {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .customer_secondary_buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .customer_secondary_buttons p {
    text-align: center;
  }
}
@media screen and (max-width: 24rem) {
  .artist_sign_up .form-inputs {
    margin-bottom: 3rem;
  }
}
