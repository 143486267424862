.customer_content_container {
  width: 100vw;
  max-width: 100%;
  display: flex;
  justify-content: center;
}
.page_wrapper_container {
  position: relative;
}
.approval_calc_button {
  position: absolute;
  top: -100px;
  right: 12%;
}
.secondary_screen_title_container {
  display: flex;
  align-items: center;
  overflow: auto;
  overflow-wrap: break-word;
  width: 90vw;
}

.navigation_arrow {
  width: 24px;
  height: 24px;
  margin-right: 0.875rem;
}
.approval_calc_main_container {
  margin-top: 46px;
  margin-bottom: 200px;
}

@media screen and (max-width: 65.6rem) {
}

@media screen and (max-width: 48rem) {
  .approval_calc_button {
    position: relative;
    top: auto;
    right: auto;
    display: flex;
    justify-content: center;
    margin-top: 37px;
  }
  .secondary_screen_title {
    font-size: 2.5rem;
    line-height: 3rem;
    left: 5%;
    margin: 0;
  }
}
