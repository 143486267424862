.profile_container {
  margin: 2.5rem 0 9rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.profile_content_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.profile_content {
  width: 50%;
}

.profile_banner {
  margin: auto;
  max-width: 686px;
  height: fit-content;
  border-radius: 10px;
  position: relative;
}

.profile_default_banner {
  width: 686px;
  height: 253px;
  background: var(--black);
  border-radius: 10px;
  margin: auto;
  position: relative;
}

.profile_banner_image {
  margin: auto;
  width: 100%;
  height: auto;
  border-radius: 10px;
  position: relative;
}

.add_banner {
  padding: 0;
  border: none;
  background: transparent;
  position: absolute;
  top: 10px;
  right: 10px;
}
.profile_details_container {
  padding-bottom: 1.125rem;
  border-bottom: 1px solid var(--profile_border);
}

.profile_details_content_container {
  display: flex;
  justify-content: space-between;
}

.profile_subtitles {
  font-size: 1rem;
  line-height: 1.188rem;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "montserrat", sans-serif;
  margin-top: 2.25rem;
}

.artist_name {
  font-size: 1.375rem;
  line-height: 1.69rem;
  font-family: "montserrat", sans-serif;
  margin: 0.75rem 0 0 0;
}

.profile_details {
  font-size: 1rem;
  line-height: 1.312rem;
  font-weight: 300;
  margin: 2px 0 0.5rem 0;
}

.dot {
  margin: 0 4px 4px 9px;
  width: 3px;
  height: 3px;
  background: var(--black);
  display: inline-block;
  border-radius: 50%;
}

.grey_dot {
  margin: 0 4px 4px 9px;
  width: 3px;
  height: 3px;
  background-color: rgb(255, 255, 255, 0.5);
  display: inline-block;
  border-radius: 50%;
}

.tag_container {
  display: flex;
}

.tag_wrapper {
  padding: 3px 6px;
  border: 1px solid var(--tag_color);
  border-radius: 4px;
  margin: 22px 10px 0 0;
}

.tag {
  font-size: 1rem;
  line-height: 1.31rem;
  color: var(--tag_color);
  font-weight: 300;
  text-transform: capitalize;
  margin: 0;
}
.edit_information_container {
  margin-top: 4rem;
}

.edit_information_button,
.add_photo_button,
.edit_description_button,
.change_password_button,
.edit_amounts_button,
.edit_banking_button {
  background: url("../../../img/buttons/btn_green_outline_med.svg");
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  width: 227px;
  height: 51px;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--green);
}

.edit_information_button:hover,
.add_photo_button:hover,
.edit_description_button:hover,
.change_password_button:hover,
.edit_amounts_button:hover,
.edit_banking_button:hover {
  filter: brightness(0.85);
}

.portfolio_header {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.portfolio_header .profile_subtitles,
.artist_description_header .profile_subtitles,
.artist_password_header .profile_subtitles,
.artist_approval_threshold_header .profile_subtitles,
.artist_amount_received_header .profile_subtitles,
.artist_banking_wrapper .profile_subtitles {
  margin-top: 0;
}

.portfolio_header p {
  margin-left: auto;
  margin-right: 19px;
}

.portfolio_images_container {
  display: flex;
  margin-top: 40px;
  border-bottom: 1px solid var(--profile_border);
  padding-bottom: 16px;
}

.portfolio_image_wrapper {
  width: 24%;
  height: auto;
  position: relative;
  margin-right: 7px;
}
.portfolio_image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  border: 1px solid var(--profile_border);
}

.portfolio_delete_image {
  position: absolute;
  top: -6%;
  right: 0;
  z-index: 2;
}
.artist_description_wrapper,
.artist_password_wrapper,
.artist_approval_threshold_wrapper,
.artist_amount_received_wrapper,
.artist_banking_wrapper {
  border-bottom: 1px solid var(--profile_border);
}
.artist_description_header,
.artist_password_wrapper,
.artist_banking_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.artist_password_wrapper,
.artist_banking_wrapper {
  padding-bottom: 28px;
}

.artist_description_container {
  margin-top: 6px;
  margin-bottom: 28px;
  width: 100%;
  height: 15vh;
  background: #ededed;
  border-radius: 6px;
  padding: 18px 11px;
}

.artist_description_container p {
  margin: 0;
  font-size: 1rem;
  line-height: 2;
  letter-spacing: 0.96px;
}

.artist_password_header,
.artist_legal_docs {
  margin-top: 10px;
}
.artist_password_header p {
  margin: 0;
}
.artist_approval_threshold_wrapper,
.artist_amount_received_wrapper {
  padding-bottom: 14px;
}

.artist_amount_received_wrapper {
  margin-top: 17px;
}
.artist_approval_threshold_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.artist_threshold_content {
  display: flex;
  font-size: 1rem;
  line-height: 1.5;
  width: 55%;
  justify-content: space-between;
}
.artist_threshold_content p {
  margin: 14px 0;
}

.artist_threshold_amounts {
  color: var(--green);
  font-weight: bold;
}

.artist_profile_links {
  padding: 21px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--profile_border);
}
.artist_profile_links .profile_subtitles {
  margin: 0;
}

.logout_button_container {
  margin-top: 44px;
  display: flex;
  justify-content: center;
}
.logout_button {
  background: url("../../../img/buttons/btn_black_large.svg");
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  width: 379px;
  height: 51px;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--white);
}

.logout_button:hover {
  filter: opacity(0.85);
}

@media screen and (max-width: 62rem) {
  .profile_content {
    width: 80%;
  }
  .tag_container {
    max-width: 95vw;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 48rem) {
  .profile_content {
    width: 90%;
  }

  .edit_information_container {
    margin-top: 14px;
    align-self: center;
  }

  .portfolio_header p {
    margin: 0;
    align-self: flex-end;
  }
  .profile_details_content_container,
  .portfolio_header,
  .artist_description_header,
  .artist_approval_threshold_header {
    flex-direction: column;
  }
  .artist_password_wrapper,
  .artist_banking_wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .profile_subtitles {
    align-self: flex-start;
  }
  .change_password_button_container {
    align-self: center;
    margin-top: 14px;
  }
  .artist_banking_wrapper .edit_banking_button_container {
    margin-top: 10px;
    align-self: center;
  }
  .artist_threshold_content {
    width: 100%;
  }
  .logout_button {
    background-size: contain;
    background-repeat: no-repeat;
    width: 341px;
    height: 45.9px;
  }
  .profile_default_banner {
    width: 300px;
    height: 125px;
    background: var(--black);
    border-radius: 10px;
    margin: auto;
    position: relative;
  }
}
