.about_us {
  background-color: var(--black);
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about_us_main {
  margin-top: 3.75rem;
  width: 70vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.about_us_title {
  font-size: 2.5rem;
  line-height: 2.375rem;
  letter-spacing: 2.4px;
  margin-bottom: 2rem;
  font-weight: 300;
  font-family: "montserrat", sans-serif;
}

.about_us_content {
  font-family: "roboto", sans-serif;
  font-size: 1rem;
  line-height: 2rem;
  letter-spacing: 0.96px;
  font-weight: 400;
}

.button {
  margin-top: 1.375rem;
}

.about_us_more {
  width: 100%;
  display: flex;
  flex-wrap:wrap;
  justify-content: space-evenly;
  padding: 0 1.468rem;
  margin-top: 3.75rem;
  margin-bottom: 11.43rem;
}

@media screen and (max-width: 62rem) {
  .about_us_main {
    width: 50%;
    margin-top: 3rem;
  }
  .about_us_title {
    margin-bottom: 1.5rem;
  }
  .about_us_content{
    line-height: 1.5rem;
  }
  .about_us_more {
    width: 100%;
    display: flex;
    flex-direction:column;
    align-items:center;
    margin-top: 3rem;
    margin-bottom: 10rem;
  }
}

@media screen and (max-width: 48rem) {
  .about_us_main {
    width: 90%;
    margin-bottom: 3rem;
  }
  .about_us_title {
    font-size: 2rem;
    line-height: 2rem;
    margin-bottom: 1.5rem;
  }
  .about_us_more {

  }
}
