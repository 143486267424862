.customer_footer{
    display: flex;
    justify-content: flex-start;
    align-items:center;
    background: var(--black);
    padding: 3rem;
}
.customer_footer_content{
    display: flex;
}

.customer_footer_links{
    display: flex;
    flex-direction: column;
    margin-left: 5rem;
}

.customer_footer_link{
    font-size: 1rem;
    line-height: 1.188rem;
    text-transform: uppercase;
    font-family: 'montserrat', sans-serif;
    font-weight: 300;
    color: var(--white);
    text-decoration: none;
    margin: .5rem 0 .5rem 0;
}

.customer_footer_logo{
    margin-left: auto;
    margin-right: 12.5rem;
}

.customer_footer_logo img{
    width:188.9px;
}

@media screen and (max-width: 62rem) {
    .customer_footer_logo{
        display: none;
    }
}

@media screen and (max-width: 48rem) {
    .customer_footer_content{
        flex-direction: column;
    }

    .customer_footer_links{
        margin-left: 1rem;
    }
}