.sm_green_button{
    background: url('../../../img/buttons/btn_green_small.svg');
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	outline: inherit;
    width: 111px;
    height: 37px;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items:center;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    text-transform: uppercase;
    color: var(--white);
}

.sm_green_button:hover {
    filter: brightness(0.85);
  }