.sign_up_modal_instruc {
  align-self: flex-start;
  margin: 0 0 1.313rem 0;
}

.tattoo_artist_button {
  background: url("../../../img/buttons/btn_green_outline_large.svg");
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  width: 379px;
  height: 51px;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--green);
  margin: 1.25rem 0 0 0;
}

.tattoo_artist_button:hover {
  filter: brightness(0.85);
}

.tos_content,
.pp_content {
  font-size: 1rem;
  line-height: 1.313rem;
  margin: 0 0 3rem 0;
}

.tos_content p,
.pp_content p {
  margin: 10px 0 10px;
}

.tos_version,
.pp_version {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  letter-spacing: 0.84px;
  line-height: 1.5rem;
  font-weight: bold;
}

@media screen and (max-width: 62rem) {
  .tattoo_artist_button {
    background-size: contain;
    background-repeat: no-repeat;
    width: 296px;
    height: 45.9px;
  }
}

@media screen and (max-width: 24rem) {
  .tattoo_artist_button {
    max-width: 250px;
  }
}
