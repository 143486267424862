.stripe_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.stripe_container button {
  margin-top: 10px;
}

.stripe_message {
  margin-bottom: 14px;
  font-size: 1.125rem;
  line-height: 1.5;
  margin-bottom: 15px;
  max-width: 360px;
}

.error_message_stripe {
  color: var(--red);
  margin-top: 20px;
}

.hidden {
  display: none;
}
