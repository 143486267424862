.landing_page{
    width: 100vw;
    max-width:100%;
}

.hero{
    background: transparent url('../../img/background_eye/background_eye.png') no-repeat center center border-box;
    background-size: cover;
    max-width:100%;
    z-index: -1;
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content:center;
    align-items: center;;
}

@media screen and (max-width: 62rem) {
    .landing_page{
        position: absolute;
        top: 0;
    }
    .hero{
        background-size: auto 70%;
        width: 100vw;
        height: 70vh;
    }
    .hero img{
        margin: 9.5em;
    }
  }
  
  @media screen and (max-width: 48rem) {
    .hero img{
        margin: 5.6em;
        width: 50%;
    }
  }
  