.home_artist_detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 25%;
  margin-top: 5rem;
}

.home_artist_detail_img {
  width: 54.68%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: #dedede;
}

.home_artist_detail_title {
  font-size: 1.875rem;
  letter-spacing: 1.8px;
  line-height: 2.375rem;
  font-weight: 400;
  margin-top: 1.25rem;
  margin-bottom: 0;
}

.home_artist_detail_subtitle {
  font-size: 1.31rem;
  letter-spacing: 1.26px;
  line-height: 2.375rem;
  font-weight: 300;
  color: var(--grey);
  margin-top: 0.875rem;
  margin-bottom: 0px;
}

.home_artist_detail_content {
  font-size: 0.875rem;
  letter-spacing: 0.84px;
  line-height: 1.5rem;
  font-weight: normal;
  font-family: "roboto", sans-serif;
  margin-top: 1rem;
}

@media screen and (max-width: 62rem) {
  .home_artist_detail {
    width: 45%;
  }
  .home_artist_detail_img {
    width: 45%;
  }
}

@media screen and (max-width: 48rem) {
  .home_artist_detail {
    width: 90%;
  }
  .home_artist_detail_img {
    width: 228px;
  }
}
